body#krakowjams h1 {
  color: #008000;
}
body#krakowjams h2 {
  color: #f00;
}
body#krakowjams div#web {
  position: absolute;
  left: 0px;
  top: 0px;
}
body#krakowjams div#web img#picture {
  width: 60%;
  height: auto;
}
@media (min-width: 700px) {
  body#krakowjams div#web img#picture {
    width: 100%;
    height: auto;
  }
}
body#krakowjams .tippy-popper .tippy-content {
  width: 219px;
  height: 70px;
  padding: 10px 4px;
  font-size: 20px !important;
  font-family: 'Sansation_Light';
}
body#krakowjams #contactMore a {
  text-decoration: none !important;
  color: #000;
}
body#krakowjams header {
  display: flex;
  justify-content: space-between;
  padding: 0px 60px;
}
@media (min-width: 700px) {
  body#krakowjams header {
    font-size: 16px;
    padding: 0px 60px;
  }
}
body#krakowjams header div.empty {
  width: 20%;
}
body#krakowjams header div.title-container {
  width: 60%;
}
body#krakowjams header .title-container {
  position: relative;
  padding-top: 60px;
}
body#krakowjams header .title-container .title {
  color: #2d2b2a;
  font-size: 16px;
  font-family: 'Sansation_Light';
  display: flex;
  justify-content: center;
  user-select: none;
  opacity: 0.8;
  text-align: center;
  opacity: 0;
  position: absolute;
  white-space: nowrap;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.4s all ease;
}
@media (min-width: 750px) and (max-width: 1023px) {
  body#krakowjams header .title-container .title {
    font-size: 25px;
  }
}
@media (min-width: 1024px) and (max-width: 1439px) {
  body#krakowjams header .title-container .title {
    font-size: 30px;
  }
}
@media (min-width: 1440px) {
  body#krakowjams header .title-container .title {
    font-size: 35px;
  }
}
body#krakowjams header .title-container.visible-0 .title.title-0 {
  opacity: 1;
}
body#krakowjams header .title-container.visible-1 .title.title-1 {
  opacity: 1;
}
body#krakowjams header .title-container.visible-2 .title.title-2 {
  opacity: 1;
}
body#krakowjams header .title-container.visible-3 .title.title-3 {
  opacity: 1;
}
body#krakowjams header .title-container.visible-4 .title.title-4 {
  opacity: 1;
}
body#krakowjams header .title-container.visible-5 .title.title-5 {
  opacity: 1;
}
body#krakowjams header .title-container.visible-6 .title.title-6 {
  opacity: 1;
}
body#krakowjams header .title-container.visible-7 .title.title-7 {
  opacity: 1;
}
body#krakowjams header .title-container.visible-8 .title.title-8 {
  opacity: 1;
}
body#krakowjams header .title-container.visible-9 .title.title-9 {
  opacity: 1;
}
body#krakowjams header .title-container.visible-10 .title.title-10 {
  opacity: 1;
}
body#krakowjams header .title-container.visible-11 .title.title-11 {
  opacity: 1;
}
body#krakowjams header .title-container.visible-12 .title.title-12 {
  opacity: 1;
}
body#krakowjams header .title-container.visible-13 .title.title-13 {
  opacity: 1;
}
body#krakowjams header .title-container.visible-14 .title.title-14 {
  opacity: 1;
}
body#krakowjams header .title-container.visible-15 .title.title-15 {
  opacity: 1;
}
body#krakowjams header .title-container.visible-16 .title.title-16 {
  opacity: 1;
}
body#krakowjams header nav {
  width: 20%;
}
body#krakowjams header nav .new-menu {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 400;
  visibility: hidden;
}
@media (min-width: 750px) {
  body#krakowjams header nav .new-menu {
    display: none;
  }
}
body#krakowjams header nav .new-menu.open {
  visibility: visible;
  overflow: hidden;
}
body#krakowjams header nav ul.new-menu-nav {
  width: 100%;
  height: 100%;
  list-style-type: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
}
body#krakowjams header nav ul.new-menu-nav li {
  position: relative;
  top: 10px;
  text-align: right;
  padding-right: 25px;
  padding-bottom: 5px;
  cursor: pointer;
  height: 20px;
}
body#krakowjams header nav ul.new-menu-nav li p {
  font-size: 40px;
}
body#krakowjams header nav ul {
  list-style-type: none;
  display: flex;
  justify-content: flex-end;
}
body#krakowjams header nav ul li#narzedzia,
body#krakowjams header nav ul li#kontakt,
body#krakowjams header nav ul li#twitter,
body#krakowjams header nav ul li#facebook {
  display: none;
}
@media (min-width: 750px) {
  body#krakowjams header nav ul li#narzedzia,
  body#krakowjams header nav ul li#kontakt,
  body#krakowjams header nav ul li#twitter,
  body#krakowjams header nav ul li#facebook {
    display: block;
  }
}
body#krakowjams header nav ul li {
  padding: 0px 2px;
  opacity: 0.5;
  z-index: 450;
}
@media (min-width: 750px) {
  body#krakowjams header nav ul li {
    padding: 0px 8px;
  }
}
body#krakowjams header nav ul li a {
  text-decoration: none;
}
body#krakowjams header nav ul li a p {
  color: #000;
  font-size: 12px;
  font-family: 'Sansation_Regular';
  padding: 2px 6px 0px 6px;
}
@media (min-width: 750px) and (max-width: 1023px) {
  body#krakowjams header nav ul li a p {
    font-size: 16px;
    padding: 10px 2px;
  }
}
@media (min-width: 1024px) {
  body#krakowjams header nav ul li a p {
    font-size: 20px;
    padding: 0px 2px;
  }
}
body#krakowjams header nav ul > *:hover {
  opacity: 1;
}
body#krakowjams header nav ul li#twitter a img,
body#krakowjams header nav ul li#facebook a img {
  padding: 20px 0px;
}
body#krakowjams header nav #nav-icon1 {
  width: 35px;
  height: 25px;
  position: absolute;
  top: 20px;
  right: 25px;
  z-index: 405;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}
@media (min-width: 750px) {
  body#krakowjams header nav #nav-icon1 {
    display: none;
  }
}
body#krakowjams header nav #nav-icon1 span {
  display: flex;
  position: relative;
  height: 5px;
  width: 100%;
  background: rgba(0,0,0,0.7);
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
body#krakowjams header nav #nav-icon1 span:nth-child(1) {
  top: 0px;
}
body#krakowjams header nav #nav-icon1 span:nth-child(2) {
  top: 5px;
}
body#krakowjams header nav #nav-icon1 span:nth-child(3) {
  top: 10px;
}
body#krakowjams header nav #nav-icon1.openSign span:nth-child(1) {
  top: 12px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}
body#krakowjams header nav #nav-icon1.openSign span:nth-child(2) {
  opacity: 0;
  left: -40px;
}
body#krakowjams header nav #nav-icon1.openSign span:nth-child(3) {
  top: 3px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
body#krakowjams header nav #nav-icon1.openSign {
  position: fixed;
  top: 20px;
  right: 25px;
}
body#krakowjams *,
body#krakowjams *::before,
body#krakowjams *::after {
  box-sizing: border-box;
}
body#krakowjams footer {
  max-width: initial;
}
body#krakowjams footer div.footer {
  max-height: 80px;
  background-color: #f2f2f2;
  border-top-width: 1px;
  border-top-color: #a1a2a2;
  border-style: solid;
  box-shadow: inset 0px 8px 6px #e3e3e3;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  display: flex;
  justify-content: space-between;
  padding: 0px 0px;
}
body#krakowjams footer div.footer ul {
  list-style-type: none;
  display: flex;
  width: 100%;
}
body#krakowjams footer div.footer ul li {
  padding: 10px 30px;
}
body#krakowjams footer div.footer ul li img {
  width: auto;
  max-width: 150px;
  height: auto;
}
body#krakowjams footer div.footer ul .newPadding {
  padding: 0px 30px 20px 30px;
}
body#krakowjams {
  margin: 0px;
}
body#krakowjams div.jars-wrapper {
  padding: 30px 23%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  min-height: calc(100vh - 80px - 100px);
}
@media (min-width: 300px) and (max-width: 389px) {
  body#krakowjams div.jars-wrapper {
    padding: 0 20%;
  }
}
@media (min-width: 390px) and (max-width: 500px) {
  body#krakowjams div.jars-wrapper {
    padding: 0 30%;
  }
}
@media (min-width: 501px) and (max-width: 600px) {
  body#krakowjams div.jars-wrapper {
    padding: 0 33%;
  }
}
@media (min-width: 601px) and (max-width: 700px) {
  body#krakowjams div.jars-wrapper {
    padding: 0 23%;
  }
}
@media (min-width: 701px) and (max-width: 840px) {
  body#krakowjams div.jars-wrapper {
    padding: 0 27%;
  }
}
@media (min-width: 841px) and (max-width: 900px) {
  body#krakowjams div.jars-wrapper {
    padding: 0 29%;
  }
}
@media (min-width: 901px) and (max-width: 1000px) {
  body#krakowjams div.jars-wrapper {
    padding: 0 15%;
  }
}
@media (min-width: 1001px) {
  body#krakowjams div.jars-wrapper {
    padding: 0 18%;
  }
}
@media (min-width: 1081px) {
  body#krakowjams div.jars-wrapper {
    padding: 0 calc((100vw - 700px) / 2);
  }
}
body#krakowjams div.jars-wrapper .jar-container {
  position: relative;
  margin: 20px 0px;
  max-width: 78px;
}
body#krakowjams div.jars-wrapper .jar-container .jar {
  width: 78px;
  height: 108px;
  position: relative;
  margin: 0 auto;
}
body#krakowjams div.jars-wrapper .jar-container .jar > img {
  position: absolute;
  transition: all 0.2s ease;
}
body#krakowjams div.jars-wrapper .jar-container .jar > img.jar {
  left: 0px;
  top: 0px;
  margin: 0px;
  z-index: 100;
  height: auto;
}
body#krakowjams div.jars-wrapper .jar-container .jar > img.glow {
  left: -4px;
  top: 0px;
  margin: 0px;
  justify-content: center;
  z-index: 200;
  height: auto;
}
body#krakowjams div.jars-wrapper .jar-container .jar > img.shadow {
  left: 0px;
  top: 0px;
  margin: 0px;
  justify-content: center;
  z-index: 30;
  height: auto;
}
body#krakowjams div.jars-wrapper .jar-container .jar > img.perspective {
  left: 0px;
  top: 0px;
  margin: 0px;
  justify-content: center;
  z-index: 40;
  height: auto;
}
body#krakowjams div.jars-wrapper .jar-container .jar > img.top {
  left: 12px;
  top: 0px;
  margin: 0px;
  justify-content: center;
  z-index: 250;
  height: auto;
}
body#krakowjams div.jars-wrapper .jar-container .jar > img.edition {
  left: -19px;
  top: 53px;
  margin: 0px;
  justify-content: center;
  z-index: 260;
  height: auto;
  width: 60px;
}
body#krakowjams div.jars-wrapper .jar-container .jar > img.jam {
  left: 9px;
  top: 9px;
  margin: 0px;
  justify-content: center;
  z-index: 150;
  height: auto;
}
body#krakowjams div.jars-wrapper .jar-container .jar:hover > img.top {
  top: -35px;
  left: -4px;
  transform: rotate(-20deg);
}
body#krakowjams div.jars-wrapper .jar-container .jar:hover > img.jar,
body#krakowjams div.jars-wrapper .jar-container .jar:hover > img.glow {
  top: -10px;
}
body#krakowjams div.jars-wrapper .jar-container .jar:hover > img.jam {
  top: -2px;
}
body#krakowjams div.jars-wrapper .jar-container div.shelf {
  position: absolute;
  bottom: -60px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  display: flex;
  justify-content: center;
}
body#krakowjams div.jars-wrapper .jar-container div.shelf div.container {
  height: 100px;
  width: 100%;
  background-repeat: repeat-x;
  position: relative;
}
body#krakowjams div.jars-wrapper .jar-container div.shelf div.container::after {
  content: "";
  display: none;
  position: absolute;
  background-image: url("http://pleomethod.pleodev.usermd.net/dist/img/krakowjams/shelf_right.png");
  background-repeat: no-repeat;
  left: 100%;
  top: 0;
  height: 100%;
  width: 44px;
  background-position: right top;
}
body#krakowjams div.jars-wrapper .jar-container div.shelf div.container::before {
  content: "";
  display: none;
  position: absolute;
  background-image: url("http://pleomethod.pleodev.usermd.net/dist/img/krakowjams/shelf_left.png");
  background-repeat: no-repeat;
  right: 100%;
  top: 0px;
  height: 100%;
  width: 44px;
  background-position: right top;
}
@media (min-width: 300px) and (max-width: 600px) {
  body#krakowjams div.jars-wrapper .jar-container:nth-child(2n - 1) div.shelf div.container::before {
    display: block;
  }
  body#krakowjams div.jars-wrapper .jar-container:nth-child(2n) div.shelf div.container::after {
    display: block;
  }
}
@media (min-width: 601px) and (max-width: 900px) {
  body#krakowjams div.jars-wrapper .jar-container:nth-child(4n - 3) div.shelf div.container::before {
    display: block;
  }
  body#krakowjams div.jars-wrapper .jar-container:nth-child(4n) div.shelf div.container::after {
    display: block;
  }
  body#krakowjams div.jars-wrapper .jar-container:last-child div.shelf div.container::after {
    display: block;
  }
}
@media (min-width: 901px) {
  body#krakowjams div.jars-wrapper .jar-container:nth-child(8n - 7) div.shelf div.container::before {
    display: block;
  }
  body#krakowjams div.jars-wrapper .jar-container:nth-child(8n) div.shelf div.container::after {
    display: block;
  }
}
body#krakowjams .event-background {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0px;
  margin: 0px;
  z-index: 500;
  border-width: 0px;
  overflow: hidden;
}
body#krakowjams .event-background .background-bar {
  height: 0px;
  width: 100%;
  position: relative;
  top: 0px;
  left: 0px;
  z-index: 550;
  cursor: pointer;
  margin: 0px;
  box-sizing: border-box;
  background-color: #f2f2f2;
  border-top-width: 1px;
  border-bottom-width: 0px;
  border-right-width: 0px;
  border-left-width: 0px;
  border-top-color: #a1a2a2;
  border-style: solid;
  box-shadow: inset 0px -10px 6px #e3e3e3;
}
body#krakowjams .event-background .background-all {
  position: fixed;
  top: 0px;
  left: 0px;
  margin: 0px;
  height: 100%;
  width: 100%;
  border-width: 0px;
  z-index: 410;
  background-size: cover;
  background-position: center;
}
body#krakowjams .event-background .background-all img {
  width: 100%;
  height: 100%;
  margin: 0px;
}
body#krakowjams .event-background .background-all .projects {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
body#krakowjams .event-background .background-all .projects ul {
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 100%;
  align-content: center;
  padding: 0px 0px;
  height: 100%;
  margin: 0px auto;
}
@media (min-width: 768px) {
  body#krakowjams .event-background .background-all .projects ul {
    padding: 50px 0px;
  }
}
body#krakowjams .event-background .background-all .projects ul li {
  padding: 5px 5px;
}
@media (min-width: 768px) {
  body#krakowjams .event-background .background-all .projects ul li {
    padding: 10px 20px;
  }
}
body#krakowjams .event-background .background-all .projects ul li img {
  width: 90px;
  height: 90px;
}
@media (min-width: 768px) {
  body#krakowjams .event-background .background-all .projects ul li img {
    width: 180px;
    height: 180px;
  }
}
body#krakowjams .event-background .background-all .projects ul li p {
  font-size: 12px;
  color: #000;
  text-align: center;
}
@media (min-width: 768px) {
  body#krakowjams .event-background .background-all .projects ul li p {
    font-size: 20px;
  }
}
body#krakowjams .event-background .background-all .projects ul .nasionko img {
  width: 300px;
  height: auto;
}
body#krakowjams .switch {
  cursor: pointer;
  position: fixed;
  left: 30px;
  bottom: 113px;
}
body#krakowjams .switch img {
  display: none;
}
@media (min-width: 425px) {
  body#krakowjams .switch img {
    visibility: visible;
    display: block;
  }
}
body#krakowjams .dark-blend {
  visibility: hidden;
  position: fixed;
  z-index: 500;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.92);
  overflow: auto;
}
body#krakowjams .dark-blend img.switch2 {
  cursor: pointer;
  position: fixed;
  left: 30px;
  bottom: 115px;
}
body#krakowjams .dark-blend.visibility {
  visibility: visible;
}
body#krakowjams .red-eyes {
  visibility: hidden;
  position: absolute;
  z-index: 500;
  top: 0px;
  left: 0px;
  width: 30px;
  height: 30px;
}
body#krakowjams .red-eyes img.eyes {
  position: absolute;
  top: 107px;
  left: 39px;
  width: 11px;
  height: auto;
}
@media (min-width: 700px) {
  body#krakowjams .red-eyes img.eyes {
    top: 179px;
    left: 64px;
    width: 22px;
  }
}
body#krakowjams .red-eyes.visibility {
  visibility: visible;
}
.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
  opacity: 0;
  position: fixed;
  transform: scale(0);
  -webkit-backface-visibility: hidden;
/* ideally, transition speed should match zoom duration */
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.mfp-with-zoom.mfp-ready .mfp-container {
  opacity: 1;
  transform: scale(1);
}
.mfp-with-zoom.mfp-ready.mfp-bg {
  opacity: 0.8;
}
.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
  opacity: 0;
  transform: scale(0);
}
.h1-global {
  font-size: 72px !important;
}
.h2-global {
  font-size: 48px !important;
}
.h3-global {
  font-size: 36px !important;
}
.h4-global {
  font-size: 24px !important;
}
.h5-global {
  font-size: 18px !important;
}
.h6-global {
  font-size: 14px !important;
}
.p1-global {
  font-size: 24px !important;
  line-height: 41px;
  font-family: 'GloberRegular' !important;
}
.p2-global {
  font-size: 18px !important;
  line-height: 1.5 !important;
  line-height: 29px !important;
}
.p3-global {
  font-size: 16px !important;
  line-height: 1.5 !important;
}
.p4-global {
  font-size: 14px !important;
  line-height: 1.5 !important;
}
.p5-global {
  font-size: 12px !important;
  line-height: 1.5 !important;
}
@media (max-width: 768px) {
  .h1-global {
    font-size: 48px !important;
  }
  .h2-global {
    font-size: 36px !important;
  }
  .h3-global {
    font-size: 28px !important;
  }
  .h4-global {
    font-size: 18px !important;
  }
  .h5-global {
    font-size: 16px !important;
  }
  .h6-global {
    font-size: 14px;
  }
  .p1-global {
    font-size: 18px !important;
    line-height: 1.5 !important;
  }
  .p2-global {
    font-size: 14px !important;
  }
  .p3-global {
    font-size: 14px;
  }
  .p4-global {
    font-size: 14px;
  }
}
@media (max-width: 375px) {
  .h1-global {
    font-size: 36px !important;
    line-height: 1.5 !important;
  }
}
@font-face {
  font-family: 'GloberRegular';
  src: url("../font/globerregular.eot");
  src: url("../font/globerregular.eot?#iefix") format('embedded-opentype'), url("../font/globerregular.woff2") format('woff2'), url("../font/globerregular.woff") format('woff'), url("../font/GloberRegular.otf") format("opentype"), url("../font/globerregular.svg") format('svg');
}
@font-face {
  font-family: 'GloberHeavy';
  src: url("../font/globerheavy.eot");
  src: url("../font/globerheavy.eot?#iefix") format('embedded-opentype'), url("../font/globerheavy.woff2") format('woff2'), url("../font/globerheavy.woff") format('woff'), url("../font/GloberHeavy.otf") format("opentype"), url("../font/globerheavy.svg") format('svg');
}
@font-face {
  font-family: 'GloberBlack';
  src: url("../font/globerblack.eot");
  src: url("../font/globerblack.eot?#iefix") format('embedded-opentype'), url("../font/globerblack.woff2") format('woff2'), url("../font/globerblack.woff") format('woff'), url("../font/GloberBlack.otf") format("opentype"), url("../font/globerblack.svg") format('svg');
}
@font-face {
  font-family: 'GloberxBold';
  src: url("../font/globerxbold.eot");
  src: url("../font/globerxbold.eot?#iefix") format('embedded-opentype'), url("../font/globerxbold.woff2") format('woff2'), url("../font/globerxbold.woff") format('woff'), url("../font/GloberxBold.otf") format("opentype"), url("../font/globerxbold.svg") format('svg');
}
@font-face {
  font-family: 'GloberBook';
  src: url("../font/globerbook.eot");
  src: url("../font/globerbook.eot?#iefix") format('embedded-opentype'), url("../font/globerbook.woff2") format('woff2'), url("../font/globerbook.woff") format('woff'), url("../font/GloberBook.otf") format("opentype"), url("../font/globerbook.svg") format('svg');
}
@font-face {
  font-family: 'GloberSemiBold';
  src: url("../font/globersemibold.eot");
  src: url("../font/globersemibold.eot?#iefix") format('embedded-opentype'), url("../font/globersemibold.woff2") format('woff2'), url("../font/globersemibold.woff") format('woff'), url("../font/GloberSemiBold.otf") format("opentype"), url("../font/globersemibold.svg") format('svg');
}
@font-face {
  font-family: 'GloberLight';
  src: url("../font/globerlight.eot");
  src: url("../font/globerlight.eot?#iefix") format('embedded-opentype'), url("../font/globerlight.woff2") format('woff2'), url("../font/globerlight.woff") format('woff'), url("../font/GloberLight.otf") format("opentype"), url("../font/globerlight.svg") format('svg');
}
@font-face {
  font-family: 'Sansation_Light';
  src: url("../font/Sansation_Light.eot");
  src: url("../font/Sansation_Light.eot?#iefix") format('embedded-opentype'), url("../font/Sansation_Light.woff2") format('woff2'), url("../font/Sansation_Light.woff") format('woff'), url("../font/SansationLight.otf") format("opentype"), url("../font/SansationLight.svg") format('svg');
}
@font-face {
  font-family: 'Sansation_Bold';
  src: url("../font/Sansation_Bold.eot");
  src: url("../font/Sansation_Bold.eot?#iefix") format('embedded-opentype'), url("../font/Sansation_Bold.woff2") format('woff2'), url("../font/Sansation_Bold.woff") format('woff'), url("../font/SansationBold.otf") format("opentype"), url("../font/SansationBold.svg") format('svg');
}
@font-face {
  font-family: 'Sansation_Regular';
  src: url("../font/Sansation_Regular.eot");
  src: url("../font/Sansation_Regular.eot?#iefix") format('embedded-opentype'), url("../font/Sansation_Regular.woff2") format('woff2'), url("../font/Sansation_Regular.woff") format('woff'), url("../font/SansationRegular.otf") format("opentype"), url("../font/SansationRegular.svg") format('svg');
}
