.h1-global
    font-size: 72px !important 
.h2-global
    font-size: 48px !important
.h3-global
    font-size: 36px !important
.h4-global
    font-size: 24px !important
.h5-global
    font-size: 18px !important
.h6-global
    font-size: 14px !important

.p1-global
    font-size: 24px !important
    line-height: 41px
    font-family: 'GloberRegular' !important
.p2-global
    font-size: 18px !important
    line-height: 1.5 !important
    line-height: 29px !important
.p3-global
    font-size: 16px !important
    line-height: 1.5 !important
.p4-global
    font-size: 14px !important
    line-height: 1.5 !important
.p5-global
    font-size: 12px !important
    line-height: 1.5 !important

@media(max-width: 768px)
    .h1-global
        font-size: 48px !important
    .h2-global
        font-size: 36px !important
    .h3-global
        font-size: 28px !important
    .h4-global
        font-size: 18px !important
    .h5-global
        font-size: 16px !important 
    .h6-global
        font-size: 14px
        
    .p1-global
        font-size: 18px !important
        line-height: 1.5 !important
    .p2-global
        font-size: 14px !important
    .p3-global
        font-size: 14px
    .p4-global
        font-size: 14px

@media (max-width: 375px)
    .h1-global
        font-size: 36px !important
        line-height: 1.5 !important

@font-face
    font-family: 'GloberRegular'
    src: url( '../font/globerregular.eot' )
    src: url( '../font/globerregular.eot?#iefix' ) format( 'embedded-opentype' ),
        url( '../font/globerregular.woff2' ) format( 'woff2' ),
        url( '../font/globerregular.woff' ) format( 'woff' ),
        url('../font/GloberRegular.otf') format("opentype"),
        url( '../font/globerregular.svg' ) format( 'svg' )

@font-face
    font-family: 'GloberHeavy'
    src: url( '../font/globerheavy.eot' )
    src: url( '../font/globerheavy.eot?#iefix' ) format( 'embedded-opentype' ),
        url( '../font/globerheavy.woff2' ) format( 'woff2' ),
        url( '../font/globerheavy.woff' ) format( 'woff' ),
        url('../font/GloberHeavy.otf') format("opentype"),
        url( '../font/globerheavy.svg' ) format( 'svg' ) 

@font-face
    font-family: 'GloberBlack'
    src: url( '../font/globerblack.eot'  )
    src: url( '../font/globerblack.eot?#iefix' ) format( 'embedded-opentype' ),
        url( '../font/globerblack.woff2' ) format( 'woff2' ),
        url( '../font/globerblack.woff' ) format( 'woff' ),
        url('../font/GloberBlack.otf') format("opentype"),
        url( '../font/globerblack.svg' ) format( 'svg' )

@font-face
    font-family: 'GloberxBold' 
    src: url( '../font/globerxbold.eot' )
    src: url( '../font/globerxbold.eot?#iefix' ) format( 'embedded-opentype' ),
        url( '../font/globerxbold.woff2' ) format( 'woff2' ),
        url( '../font/globerxbold.woff' ) format( 'woff' ),
        url('../font/GloberxBold.otf') format("opentype"),
        url( '../font/globerxbold.svg' ) format( 'svg' )

// @font-face
//     font-family: 'GloberBold'
//     src: url( '../font/globerbold.eot' )
//     src: url( '../font/globerbold.eot?#iefix' ) format( 'embedded-opentype' ),
//         url( '../font/globerbold.woff2' ) format( 'woff2' ),
//         url( '../font/globerbold.woff' ) format( 'woff' ),
//         url('../font/GloberBold.otf') format("opentype"),
//         url( '../font/globerbold.svg' ) format( 'svg' )

@font-face
    font-family: 'GloberBook'
    src: url( '../font/globerbook.eot' )
    src: url( '../font/globerbook.eot?#iefix' ) format( 'embedded-opentype' ),
        url( '../font/globerbook.woff2' ) format( 'woff2' ),
        url( '../font/globerbook.woff' ) format( 'woff' ),
        url('../font/GloberBook.otf') format("opentype"),
        url( '../font/globerbook.svg' ) format( 'svg' )

@font-face
    font-family: 'GloberSemiBold'
    src: url( '../font/globersemibold.eot' )
    src: url( '../font/globersemibold.eot?#iefix' ) format( 'embedded-opentype' ),
        url( '../font/globersemibold.woff2' ) format( 'woff2' ),
        url( '../font/globersemibold.woff' ) format( 'woff' ),
        url('../font/GloberSemiBold.otf') format("opentype"),
        url( '../font/globersemibold.svg' ) format( 'svg' )

@font-face
    font-family: 'GloberLight'
    src: url( '../font/globerlight.eot' )
    src: url( '../font/globerlight.eot?#iefix' ) format( 'embedded-opentype' ),
        url( '../font/globerlight.woff2' ) format( 'woff2' ),
        url( '../font/globerlight.woff' ) format( 'woff' ),
        url('../font/GloberLight.otf') format("opentype"),
        url( '../font/globerlight.svg' ) format( 'svg' )

@font-face
    font-family: 'Sansation_Light'
    src: url( '../font/Sansation_Light.eot' )
    src: url( '../font/Sansation_Light.eot?#iefix' ) format( 'embedded-opentype' ),
        url( '../font/Sansation_Light.woff2' ) format( 'woff2' ),
        url( '../font/Sansation_Light.woff' ) format( 'woff' ),
        url('../font/SansationLight.otf') format("opentype"),
        url( '../font/SansationLight.svg' ) format( 'svg' )

@font-face
    font-family: 'Sansation_Bold'
    src: url( '../font/Sansation_Bold.eot' )
    src: url( '../font/Sansation_Bold.eot?#iefix' ) format( 'embedded-opentype' ),
        url( '../font/Sansation_Bold.woff2' ) format( 'woff2' ),
        url( '../font/Sansation_Bold.woff' ) format( 'woff' ),
        url('../font/SansationBold.otf') format("opentype"),
        url( '../font/SansationBold.svg' ) format( 'svg' )

@font-face
    font-family: 'Sansation_Regular'
    src: url( '../font/Sansation_Regular.eot' )
    src: url( '../font/Sansation_Regular.eot?#iefix' ) format( 'embedded-opentype' ),
        url( '../font/Sansation_Regular.woff2' ) format( 'woff2' ),
        url( '../font/Sansation_Regular.woff' ) format( 'woff' ),
        url('../font/SansationRegular.otf') format("opentype"),
        url( '../font/SansationRegular.svg' ) format( 'svg' )