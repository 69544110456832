largeDesktopJarNumber = 8;
largeDesktopWidth = calc(( 100vw - 700px) /2)
body#krakowjams
	// overflow: hidden
	margin: 0px
	div.jars-wrapper
		padding: 30px 23%
		max-width: 100%
		display: flex
		justify-content: center
		flex-wrap: wrap 
		align-content: center
		align-items: center
		min-height: calc( 100vh - 80px - 100px )
		@media(min-width: 300px) and (max-width: 389px)
			padding: 0 20%
		@media(min-width: 390px) and (max-width: 500px)
			padding: 0 30%
		@media(min-width: 501px) and (max-width: 600px)
			padding: 0 33%
		@media(min-width: 601px) and (max-width: 700px)
			padding: 0 23%
		@media(min-width: 701px) and (max-width: 840px)
			padding: 0 27% 
		@media(min-width: 841px) and (max-width: 900px)
			padding: 0 29% 
		@media(min-width: 901px) and (max-width: 1000px)
			padding: 0 15%
		@media(min-width: 1001px) 
			padding: 0 18%
		@media(min-width: 1081px)
			padding: 0 largeDesktopWidth
		.jar-container
			position relative
			margin: 20px 0px
			max-width: 78px
			// @media( min-width: 1200px )
			// // 	width: "calc(100% / %s)" % largeDesktopJarNumber
			.jar
				width: 78px
				height: 108px
				position: relative
				margin: 0 auto 
				& > img 
					position: absolute
					transition: all .2s ease
					&.jar
						left: 0px
						top: 0px
						margin: 0px
						z-index:100
						height: auto
					&.glow
						left: -4px
						top: 0px
						margin: 0px
						justify-content: center
						z-index:200
						height: auto
					&.shadow
						left: 0px
						top: 0px
						margin: 0px
						justify-content: center
						z-index:30 
						height: auto
					&.perspective
						left: 0px
						top: 0px
						margin: 0px
						justify-content: center
						z-index:40
						height: auto
					&.top
						left: 12px
						top: 0px
						margin: 0px
						justify-content: center
						z-index:250
						height: auto
					&.edition
						left: -19px
						top: 53px
						margin: 0px
						justify-content: center
						z-index:260
						height: auto
						width: 60px
					&.jam
						left: 9px
						top: 9px
						margin: 0px
						justify-content: center
						z-index:150
						height: auto
				&:hover
					& > img
						&.top
							top: -35px
							left: -4px
							transform: rotate(-20deg)
						&.jar,
						&.glow
							top: -10px
						&.jam
							top: -2px 
			div.shelf
				position: absolute
				bottom: -60px
				left: 50%
				transform: translateX(-50%)
				width: 100%
				display:flex
				justify-content: center
				div.container
					height: 100px
					width: 100%
					background-repeat: repeat-x
					position: relative
					&::after
						content:""
						display: none
						position: absolute
						background-image: url('http://pleomethod.pleodev.usermd.net/dist/img/krakowjams/shelf_right.png')
						background-repeat: no-repeat
						left: 100%
						top: 0
						height: 100%
						width: 44px
						background-position: right top
					&::before 
						content:""
						display: none
						position: absolute
						background-image: url('http://pleomethod.pleodev.usermd.net/dist/img/krakowjams/shelf_left.png')
						background-repeat: no-repeat
						right: 100%
						top: 0px
						height: 100%
						width: 44px
						background-position: right top
			// &:first-child
			// 	div.shelf
			// 		div.container
			// 			&::before
			// 				display: block
			// &:last-child
			// 	div.shelf
			// 		div.container
			// 			&::after
			// 				display: block
			@media(min-width: 300px) and (max-width: 600px)
				&:nth-child(2n - 1)
					div.shelf
						div.container
							&::before
								display: block
				&:nth-child(2n)
					div.shelf
						div.container
							&::after
								display: block
			@media(min-width: 601px ) and (max-width: 900px)
				&:nth-child(4n - 3)
					div.shelf
						div.container
							&::before
								display: block
				&:nth-child(4n)
					div.shelf
						div.container
							&::after
								display: block
				&:last-child
					div.shelf
						div.container
							&::after
								display: block
			@media(min-width: 901px)
				&:nth-child(8n - 7)
					div.shelf
						div.container
							&::before
								display: block
				&:nth-child(8n)
					div.shelf 
						div.container
							&::after
								display: block
	.event-background
		height 100%
		width: 100%
		position: fixed
		top: 0
		left: 0px
		margin: 0px
		z-index: 500
		border-width: 0px
		overflow: hidden
		.background-bar
			height: 0px
			width: 100%
			position: relative
			top: 0px
			left: 0px
			z-index: 550
			cursor: pointer
			margin: 0px
			box-sizing: border-box
			background-color: #f2f2f2
			border-top-width: 1px
			border-bottom-width: 0px
			border-right-width: 0px
			border-left-width: 0px
			border-top-color: #a1a2a2
			border-style: solid 
			box-shadow: inset 0px -10px 6px #e3e3e3
		.background-all
			position: fixed
			top: 0px
			left: 0px
			margin: 0px
			height: 100%
			width: 100%
			border-width: 0px
			z-index: 410
			background-size: cover
			background-position: center
			// background-image: url('{{ event.glowneTlo.one().url }}')	
			img
				width: 100%
				height: 100%
				margin: 0px
			.projects
				position: absolute
				top: 0px
				left: 0px
				width: 100%
				height: 100%
				ul
					list-style-type: none
					display: flex
					justify-content: center
					align-items: center
					flex-wrap: wrap
					max-width: 100%
					align-content: center
					padding: 0px 0px
					height: 100%
					margin: 0px auto
					@media(min-width: 768px)
						padding: 50px 0px
					li
						padding: 5px 5px
						@media(min-width: 768px)
							padding: 10px 20px
						img 
							width: 90px
							height: 90px
							@media(min-width: 768px)
								width: 180px
								height: 180px	
						p
							font-size: 12px
							// color: #a1a2a2
							color: black
							text-align: center
							@media (min-width: 768px)
								font-size: 20px
					.nasionko
						img
							width: 300px
							height: auto		
	.switch
		cursor: pointer
		position: fixed
		left: 30px
		bottom: 113px
		img
			display: none
			@media(min-width: 425px)
				visibility: visible
				display: block
	.dark-blend
		visibility: hidden
		position: fixed
		z-index: 500
		top: 0px
		left: 0px
		width: 100%
		height: 100%
		background-color: rgba(0,0,0,0.92)
		overflow: auto
		img.switch2
			cursor: pointer
			position: fixed
			left: 30px
			bottom: 115px
		&.visibility
			visibility: visible
	.red-eyes
		visibility: hidden
		position: absolute
		z-index: 500
		top: 0px
		left: 0px
		width: 30px
		height: 30px
		// overflow: auto
		img.eyes
			position: absolute
			top: 107px
			left: 39px
			width: 11px
			height: auto
			@media(min-width: 700px)
				top: 179px
				left: 64px
				width: 22px
		&.visibility
			visibility: visible
.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg
	opacity: 0
	position: fixed
	transform: scale(0)
	-webkit-backface-visibility: hidden
	/* ideally, transition speed should match zoom duration */
	-webkit-transition: all 0.3s ease-out
	-moz-transition: all 0.3s ease-out
	-o-transition: all 0.3s ease-out
	transition: all 0.3s ease-out
.mfp-with-zoom.mfp-ready .mfp-container
	opacity: 1
	transform: scale(1)
.mfp-with-zoom.mfp-ready.mfp-bg
	opacity: 0.8
.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg 
	opacity: 0
	transform: scale(0)
