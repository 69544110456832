body#krakowjams
	*, *::before, *::after 
		box-sizing: border-box
	footer
		max-width: initial
		div.footer
			max-height: 80px
			background-color: #f2f2f2
			border-top-width: 1px
			border-top-color: #a1a2a2
			border-style: solid 
			box-shadow: inset 0px 8px 6px #e3e3e3
			border-left-width: 0px
			border-right-width: 0px
			border-bottom-width: 0px
			display: flex
			justify-content: space-between
			padding: 0px 0px
			ul
				list-style-type: none
				display: flex
				width: 100%
				// max-height: 100% 
				li
					padding: 10px 30px
					img 
						// max-height: 80px
						// max-height: 50%
						// max-width: 50% 
						// width: 160px
						width: auto
						max-width: 150px
						height: auto
				.newPadding
					padding: 0px 30px 20px 30px   
