body#krakowjams
	div#web
		position: absolute
		left: 0px 
		top: 0px
		img#picture 
			width: 60%
			height: auto
			@media(min-width: 700px)
				width: 100%
				height: auto
	.tippy-popper
		.tippy-content 
			width: 219px
			height: 70px
			padding: 10px 4px
			font-size: 20px !important
			font-family: 'Sansation_Light' 
	#contactMore
		a
			text-decoration: none !important
			color: black
	header
		display: flex
		justify-content: space-between
		padding: 0px 60px
		@media( min-width: 700px )
			font-size: 16px
			padding: 0px 60px
		// > *
		// 	width: 33.333%
		div.empty
			width: 20%
		div.title-container
			width: 60%
		.title-container
			position: relative
			padding-top: 60px
			.title
				color: #2d2b2a
				font-size: 16px
				font-family: 'Sansation_Light'
				display: flex
				justify-content: center
				user-select: none
				opacity: .8
				text-align: center
				opacity: 0
				position absolute
				white-space: nowrap
				top: 50%
				left: 50%
				transform: translate(-50%, -50%)
				transition: 0.4s all ease
				@media( min-width: 750px ) and ( max-width: 1023px )
					font-size: 25px
				@media( min-width: 1024px ) and ( max-width: 1439px )
					font-size: 30px
				@media( min-width: 1440px )
					font-size: 35px
			for i in (0..16)
				&.visible-{i}
					.title.title-{i}
						opacity: 1
				// &.visible-1
				// 	.title.title-1
		nav
			width: 20%
			.new-menu
				position: fixed
				top: 0px
				left: 0px
				width: 100%
				height: 100%
				z-index: 400
				visibility: hidden
				@media(min-width: 750px)
					display:none
				// opacity: 0
				&.open
					visibility: visible
					// opacity: 1
					overflow: hidden
			& ul.new-menu-nav
				width: 100%
				height: 100%
				list-style-type: none
				display: block
				position: absolute 
				top: 0px
				left: 0px
				li
					position: relative
					top: 10px
					text-align: right
					padding-right: 25px
					padding-bottom: 5px
					cursor: pointer
					height: 20px
					p
						font-size: 40px
			ul
				list-style-type: none
				display: flex
				justify-content: flex-end
				li#narzedzia,
				li#kontakt,
				li#twitter,
				li#facebook
					display: none
					@media(min-width:750px)
						display: block
				li
					padding: 0px 2px
					opacity: .5
					z-index: 450
					@media( min-width: 750px )
						padding: 0px 8px
					a
						text-decoration: none	
						p
							color: black
							font-size: 12px // w projekcie 16px
							font-family: 'Sansation_Regular'
							padding: 2px 6px 0px 6px
							@media( min-width: 750px ) and ( max-width: 1023px)
								font-size: 16px
								padding: 10px 2px
							@media( min-width: 1024px )
								font-size: 20px
								padding: 0px 2px
				> *
					&:hover
						opacity: 1
				li#twitter,
				li#facebook
					a
						img 
							padding: 20px 0px
			#nav-icon1
				width: 35px
				height: 25px
				position: absolute
				top: 20px
				right: 25px
				z-index: 405
				-webkit-transform: rotate(0deg)
				-moz-transform: rotate(0deg)
				-o-transform: rotate(0deg)
				transform: rotate(0deg)
				-webkit-transition: .5s ease-in-out
				-moz-transition: .5s ease-in-out
				-o-transition: .5s ease-in-out
				transition: .5s ease-in-out
				cursor: pointer
				@media(min-width: 750px)
					display:none
			#nav-icon1 span 
				display: flex
				position: relative
				height: 5px
				width: 100%
				background:rgba(0,0,0,0.7)
				border-radius: 9px
				opacity: 1
				left: 0
				-webkit-transform: rotate(0deg)
				-moz-transform: rotate(0deg)
				-o-transform: rotate(0deg)
				transform: rotate(0deg)
				-webkit-transition: .25s ease-in-out
				-moz-transition: .25s ease-in-out
				-o-transition: .25s ease-in-out
				transition: .25s ease-in-out
			#nav-icon1 span:nth-child(1) 
				top: 0px
			#nav-icon1 span:nth-child(2) 
				top: 5px
			#nav-icon1 span:nth-child(3) 
				top: 10px
			#nav-icon1.openSign span:nth-child(1) 
				top: 12px
				-webkit-transform: rotate(135deg)
				-moz-transform: rotate(135deg)
				-o-transform: rotate(135deg)
				transform: rotate(135deg)
			#nav-icon1.openSign span:nth-child(2) 
				opacity: 0
				left: -40px
			#nav-icon1.openSign span:nth-child(3) 
				top: 3px
				-webkit-transform: rotate(-135deg)
				-moz-transform: rotate(-135deg)
				-o-transform: rotate(-135deg)
				transform: rotate(-135deg)
			#nav-icon1.openSign
				position: fixed
				top: 20px
				right: 25px



